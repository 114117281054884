import {inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";

export const Page = {
  // Top
  Top: '/',

  // Dashboard
  DashboardTop: '/dashboard',
  DashboardRecent: '/dashboard/:teamId/recent',
  DashboardInvited: '/dashboard/invited',
  DashboardBookmarked: '/dashboard/bookmarked',
  DashboardArchived: '/dashboard/archived',
  DashboardTrashBox: '/dashboard/trash-box',

  // App Settings
  AppSettingTop: '/settings',

  Space: '/spaces/:spaceId',
  // Meeting
  Meeting: '/spaces/:spaceId/meeting',
  MeetingAdd: '/spaces/:spaceId/meeting/add',
  MeetingEdit: '/spaces/:spaceId/meeting/edit/:meetingId',
  MeetingHome: '/spaces/:spaceId/meeting/home',
  MeetingScheduled: '/spaces/:spaceId/meeting/scheduled',
  MeetingRecent: '/spaces/:spaceId/meeting/recent',
  MeetingAll: '/spaces/:spaceId/meeting/all',
  // Task
  Task: '/spaces/:spaceId/task',
  TaskAll: '/spaces/:spaceId/task/all',
  TaskImportant: '/spaces/:spaceId/task/important',
  TaskDeadline: '/spaces/:spaceId/task/deadline',
  TaskExpired: '/spaces/:spaceId/task/expired',
  // Note
  NoteTop: '/spaces/:spaceId/notes',
  NoteRecent: '/spaces/:spaceId/notes/recent',
  NoteStarred: '/spaces/:spaceId/notes/starred',
  NoteDetail: '/spaces/:spaceId/notes/:noteId',
  // File
  FileTop: '/spaces/:spaceId/files',
  FileHome: '/spaces/:spaceId/files/home',
  FolderRoot: '/spaces/:spaceId/files/folder',
  Folder: '/spaces/:spaceId/files/folder/:folderId',
  FileTrashBox: '/spaces/:spaceId/files/trash-box',
  // Space Settings
  SpaceSettingTop: '/spaces/:spaceId/settings',
  SpaceSettingGeneral: '/spaces/:spaceId/settings/general',
  SpaceSettingMembers: '/spaces/:spaceId/settings/members',
  SpaceSettingIntegrations: '/spaces/:spaceId/settings/integrations',
  SpaceSettingPersonal: '/spaces/:spaceId/settings/personal',

  BoardDetail: '/board/:meetingId',
} as const;

export type Page = typeof Page[keyof typeof Page];

export type PathParameters = {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class PageNavigatorService {

  private readonly router = inject(Router)

  navigateToPage(page: Page, pathParameters?: PathParameters): Promise<boolean> {
    return this.router.navigateByUrl(this.buildUrl(page, pathParameters));
  }

  openPageInNewTab(page: Page, pathParameters?: PathParameters): void {
    const url = `${window.location.origin}${this.buildUrl(page, pathParameters)}`;
    window.open(url);
  }

  buildUrl(page: Page, pathParameters?: PathParameters): string {
    return Object.entries(pathParameters ?? {}).reduce((url: string, [key, value]) => {
      return url.replace(`:${key}`, value);
    }, page);
  }

  getAbsoluteUrl(page: Page, pathParameters?: PathParameters):string {
    return new URL(this.buildUrl(page, pathParameters), window.location.href).href
  }
}
